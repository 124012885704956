import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Common/Footer/Footer';
import NonAuthHeader from '../Common/Header/NonAuthHeader';
import { getFromStorage } from '../../utils/storage';
import { useMobileToWeb } from '../../store/Positions/mobileToWeb';

const NonAuthLayout = (props) => {
  const isMobileToWeb = useMobileToWeb(
    (state) => state.isMobileToWeb
  );
  return (
    <React.Fragment>
      {' '}
      <NonAuthHeader />
      {props.children}
      {(isMobileToWeb || getFromStorage("mobiletoweb") || window.location.pathname == '/webview' || window.location.pathname == '/zoom-webview' || getFromStorage("viewPaytm")) ?
        <></>
        :
        <Footer />
      }
    </React.Fragment >
  );
};

export default NonAuthLayout;
