import clevertap from "clevertap-web-sdk";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { initializeApp } from "firebase/app";
import Smartlook from "smartlook-client";
const firebaseConfig = {
  apiKey: "AIzaSyCLSCfXVDL-ABfH7hfXrHWRA4lLQOyQRTk",
  authDomain: "hedged-321407.firebaseapp.com",
  projectId: "hedged-321407",
  storageBucket: "hedged-321407.appspot.com",
  messagingSenderId: "921048349840",
  appId: "1:921048349840:web:001d48f52d30eedba86c15",
  measurementId: "G-ZW86Z0ESRQ",
};

let FB = window.FB;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//const analytics = getAnalytics();
export const formatAmount = (amount) => {
  if (amount % 1 !== 0) {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return Number(amount)
      .toFixed()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$&,");
  }
};

export const captureEvent = (name, data = {}) => {
  // console.log("clverTap", `web_${name}`, data);
  clevertap.event.push(`web_${name}`, data);
  logEvent(analytics, `web_${name}`, data);
  if (window.fbq) {
    window.fbq("track", `web_${name}`, data);
  }
  Smartlook.track(`web_${name}`);
};

export const userLoginCleverTap = (data = {}) => {
  try {
    clevertap.onUserLogin.push({
      Site: {
        ...data,
        "MSG-email": false,                // Disable email notifications
        "MSG-push": true,                  // Enable push notifications
        "MSG-sms": true,                   // Enable sms notifications
        "MSG-whatsapp": true,
      },
    });
  } catch (error) { }
};

export const userLogoutCleverTap = (data = {}) => { };

export const userLoginFirebase = (data = {}) => {
  setUserId(analytics, data.email);
  setUserProperties(analytics, { email: data.email });
};
export const userLoginFacebook = (data = {}) => {
  FB.getLoginStatus(function (response) {
    if (response.status === "connected") {
      // The user is logged in and has authenticated your
      // app, and response.authResponse supplies
      // the user's ID, a valid access token, a signed
      // request, and the time the access token
      // and signed request each expire.
      // var uid = response.authResponse.userID;
      // var accessToken = response.authResponse.accessToken;
    } else if (response.status === "not_authorized") {
      // The user hasn't authorized your application.  They
      // must click the Login button, or you must call FB.login
      // in response to a user gesture, to launch a login dialog.
    } else {
      // The user isn't logged in to Facebook. You can launch a
      // login dialog with a user gesture, but the user may have
      // to log in to Facebook before authorizing your application.
    }
  });
};

export const userLogoutFacebook = (data = {}) => {
  FB.logout(function (response) {
    // user is now logged out
  });
};

// window.dataLayer = window.dataLayer || [];

// export const initGA = (measurementId) => {
//   window.dataLayer.push({
//     'gtm.start': new Date().getTime(),
//     event: 'gtm.js',
//   });

//   const script = document.createElement('script');
//   script.async = true;
//   script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
//   document.head.appendChild(script);

//   script.onload = () => {
//     window.gtag('config', measurementId);
//   };
// };

// export const getAnalyticsLogEvent = (category, action, userId) => {
//   window.gtag('event', action, {
//     event_category: category,
//     user_id: userId,
//   });
// };

export const constants = {
  baseUrl: "https://api.staging.hedged.in/",
  broker_base_Url: "https://staging-dot-orders-hedged-in.appspot.com/",
  // baseUrl: process.env.REACT_APP_API_URL || "https://api.production.hedged.in/",
  // broker_base_Url: process.env.REACT_APP_SWAGGER_API_URL || "https://orders.hedged.in/",
  isProd: process.env.isProd || false,
  // baseUrl: "http://192.168.1.36:5001/",
};
