import { lazy } from "react";
// import Zoommettingdetails from "../pages/HedgedTV/zoomMeeting";

const Inbox = lazy(() => import("../pages/Inbox/index"));
const LiveFunds = lazy(() => import("../pages/LiveFunds/index"));
const HedgedTV = lazy(() => import("../pages/HedgedTV/index"));
const Subscription = lazy(() => import("../pages/Subscription/index"));
const News = lazy(() => import("../pages/News/index"));
const Register = lazy(() => import("../pages/Authentication/Register"));
const Login = lazy(() => import("../pages/Authentication/Login"));
const WebinarForm = lazy(() => import("../pages/Authentication/WebinarForm"));
const ForgetPassword = lazy(() =>
  import("../pages/Authentication/ForgotPassword")
);
// const ContactUs = lazy(() => import("../components/Common/Footer/ContactUs"));
const CallDetails = lazy(() => import("../pages/Inbox/CallDetails"));
const HedgedResultPage = lazy(() => import("../pages/HedgedResult/index"));
const ManageTrackList = lazy(() => import("../pages/User/ManageTrackList"));
const OtpScreen = lazy(() => import("../pages/Authentication/OtpScreen"));
const Profile = lazy(() => import("../pages/User/Profile"));
const UserEditProfile = lazy(() => import("../pages/User/UserEditProfile"));
const MySubscription = lazy(() => import("../pages/User/MySubscription"));
const MyVideos = lazy(() => import("../pages/User/MyVideos"));
// const ChatPage = lazy(() => import("../pages/ChatPage"));
const VideoDetails = lazy(() => import("../pages/HedgedTV/VideoDetails"));
const ZoomMeeting = lazy(() => import("../pages/HedgedTV/Zoommettingdetails"));

const ResetPassword = lazy(() =>
  import("../pages/Authentication/ResetPassword")
);
const paymentPage = lazy(() => import("../pages/Subscription/paymentPage"));
const Disclaimer = lazy(() => import("../pages/Inbox/Disclaimer"));
const Page404 = lazy(() => import("../pages/Page404"));
const IntradayCallDetails = lazy(() =>
  import("../pages/Inbox/IntradayCallDetails")
);
const ZerodhaSuccess = lazy(() => import("../pages/Zerodha/index"));
const bigulSuccess = lazy(() => import("../pages/Bigul/index"));
const brokerSuccessLogin = lazy(() => import("../pages/BrokerLogin/index"));
const CallDetailsAction = lazy(() =>
  import("../pages/Inbox/CalldetailsAction")
);
const Positions = lazy(() => import("../pages/Inbox/Positions"));
const CallDetailsDemo = lazy(() => import("../pages/Inbox/CallDetailsDemo"));
const LiveFundsOrder = lazy(() => import("../pages/LiveFunds/liveFundsOrder"))
const MobileToDekstop = lazy(() => import("../pages/MobileToDekstop/index"))
const AddBroker = lazy(() => import('../pages/AddBroker/index'))
const PostLogin = lazy(() => import('../pages/Postlogin/index'))
const GenerateHtml = lazy(() => import('../pages/GenenerateHtml/index'))
const AceOfSpades = lazy(() => import('../pages/AceOfSpades'))



const authProtectedRoutes = [
  { path: "/inbox", component: Inbox },
  { path: "/livefunds", component: LiveFunds },
  { path: "/subscription", component: Subscription },
  { path: "/news", component: News },
  { path: "/hedged-result", component: HedgedResultPage },
  // { path: "/call-details", component: CallDetails },
  { path: "/hedgedTVList", component: HedgedTV },
  { path: "/hedgedtv/:id", component: VideoDetails },

  { path: "/call-details/:type/:id", component: CallDetails },
  { path: "/spade-call-details/:type/:id", component: CallDetails },
  { path: "/hedgedzoom", component: ZoomMeeting },
  { path: "/hedgedtv/:id", component: VideoDetails },
  // { path: "/livefundsorder", component: liveFundsOrder },
  { path: "/hedgedzoom", component: ZoomMeeting },
  { path: "/livefund/place/:id", component: LiveFundsOrder },
  { path: "/intraday-call-details", component: IntradayCallDetails },
  { path: "/call-details-action", component: CallDetailsAction },
  { path: "/call-details-demo", component: CallDetailsDemo },
  { path: "/positions", component: Positions },
  // { path: "/spade-positions", component: Positions },
  { path: "/manage-track-list", component: ManageTrackList },
  { path: "/profile", component: Profile },
  { path: "/user-edit-profile", component: UserEditProfile },
  { path: "/my-subscription", component: MySubscription },
  { path: "/my-videos", component: MyVideos },
  //{ path: '/chat', component: ChatPage },
  { path: "/disclaimer", component: Disclaimer },
  { path: "/payment", component: paymentPage },
  { path: "/zerodha", component: ZerodhaSuccess },
  { path: "/aos", component: AceOfSpades },

  // { path: "/broker/:type", component: brokerSuccessLogin },
  // { path: "/register", component: WebinarForm },
];

const publicRoutes = [
  { path: "/signup", component: Register },
  { path: "/login", component: Login },
  // { path: "/register", component: WebinarForm },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/otp", component: OtpScreen },
  { path: "/reset-password", component: ResetPassword },
];

const openRoutes = [
  // { path: '/contact', component: ContactUs },
  { path: "/broker/:type", component: brokerSuccessLogin },
  { path: "/zoom-webview", component: MobileToDekstop },
  { path: "/webview", component: MobileToDekstop },

  // { path: "/generateHtml", component: GenerateHtml },
  { path: "/addbroker", component: AddBroker },
  { path: "/bigul", component: bigulSuccess },
  // { path: "/hedgedTVList", component: HedgedTV },
  // { path: "/hedgedtv/:id", component: VideoDetails },
  { path: "/register", component: WebinarForm },
  { path: "*", component: Page404 },
];

export { authProtectedRoutes, publicRoutes, openRoutes };
